import React, {useEffect, useRef, useState} from 'react'
import {ModuleSerializer, Video} from '../components'
import FadeIn from '../components/UI/atoms/FadeIn'
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	GridItem,
	Heading,
	SimpleGrid,
	Text
} from '@chakra-ui/react'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'
import useGotLight from '../hooks/useGotLight'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/parallax'
import 'swiper/css/effect-coverflow'
import {SanityImage} from '../gatsby-source-sanity'
import {AnimatePresence, motion, useScroll, useSpring, useTransform} from 'framer-motion'

const GotLightPage = () => {
	const {
		title,
		_rawIntroContent,
		video,
		logo,
		_rawMainContent,
		_rawShowMore,
		images,
		sections
	} = useGotLight()

	return <React.Fragment>
		<FadeIn delayOrder={0.25}>
			<Flex alignItems={'center'} justifyContent={'center'} my={responsiveFontSize(78)}>
				<SanityImage image={logo} maxW={responsiveFontSize(270)}/>
			</Flex>
		</FadeIn>
		{title && <FadeIn delayOrder={0.5}>
			<Text fontSize={responsiveFontSize(25)} lineHeight={responsiveFontSize(35)} textAlign={'center'} w={'full'}
						mt={responsiveFontSize(20)} mb={responsiveFontSize(75)}>{title}</Text>
		</FadeIn>}
		<FadeIn delayOrder={0.75}>
			<Box fontSize={responsiveFontSize(25)} lineHeight={responsiveFontSize(35)} textAlign={'center'}
					 maxW={responsiveFontSize(845)} mx={'auto'} mb={responsiveFontSize(35)}>
				<ModuleSerializer blocks={_rawIntroContent}/>
			</Box>
		</FadeIn>
		<FadeIn delayOrder={0.25}>
			<Box fontSize={responsiveFontSize(22)} lineHeight={responsiveFontSize(28)} textAlign={'center'}
					 maxW={responsiveFontSize(845)} mx={'auto'}>
				<ModuleSerializer blocks={_rawMainContent}/>
			</Box>
			{_rawShowMore && <Accordion allowToggle>
					<AccordionItem border={'none'}>
						{({isExpanded}) => (<React.Fragment>
							<AccordionButton fontSize={responsiveFontSize(22)} lineHeight={'1em'} py={responsiveFontSize(22)} w={'auto'} mx={'auto'} my={responsiveFontSize(30)}>
								<Heading as={'span'} flex={1} textAlign={'left'}>
									{isExpanded ? 'Show less' : 'Show more'}
								</Heading>
							</AccordionButton>
							<AccordionPanel fontSize={responsiveFontSize(22)} lineHeight={responsiveFontSize(28)} textAlign={'center'}
															maxW={responsiveFontSize(845)} mx={'auto'} p={0}>
								<ModuleSerializer blocks={_rawShowMore}/>
							</AccordionPanel>
						</React.Fragment>)}
					</AccordionItem>
				</Accordion>}
		</FadeIn>
		{images && <FadeIn delayOrder={0.25}>
			<Box mx={'-' + responsiveFontSize(60)} my={responsiveFontSize(80)}>
				<Swiper
					speed={500}
					slidesPerView={2}
					loop
					grabCursor
					centeredSlides
					effect={'coverflow'}
					coverflowEffect={{
						rotate: 45,
						stretch: 0,
						depth: 100,
						modifier: 1,
						slideShadows: false
					}}
				>
					{images.map(image => <SwiperSlide key={'slide-' + image.asset.url}>
						<Box px={responsiveFontSize(30)}>
							<SanityImage image={image} border={'2px solid'}/>
						</Box>
					</SwiperSlide>)}
				</Swiper>
			</Box>
		</FadeIn>}
		<SimpleGrid columns={1} gap={responsiveFontSize(100)} mt={responsiveFontSize(150)}>
			{sections && sections.map((section, index) => {
				const isLeft = index % 2 === 0
				return <FadeIn delayOrder={0.25} key={'light-section-' + index}>
					<SimpleGrid columns={[1, 1, 1, 1, 5]} gap={responsiveFontSize(100)}>
						<GridItem colSpan={[1, 1, 1, 1, 2]} order={isLeft ? 2 : 1}>
							<SanityImage image={section.banner} border={'2px solid black'}/>
						</GridItem>
						<GridItem colSpan={[1, 1, 1, 1, 3]} order={isLeft ? 1 : 2}>
							<Heading fontSize={responsiveFontSize(80)} lineHeight={responsiveFontSize(60)}
											 mb={responsiveFontSize(30)}>{section.title}</Heading>
							<Box fontSize={responsiveFontSize(22)} lineHeight={responsiveFontSize(32)}>
								<ModuleSerializer blocks={section._rawContent}/>
							</Box>
						</GridItem>
					</SimpleGrid>
					{section._rawReadMore && <Accordion allowToggle mt={responsiveFontSize(62)}>
						<AccordionItem borderColor={'black'}>
							{({isExpanded}) => (<React.Fragment>
								<AccordionButton fontSize={responsiveFontSize(22)} lineHeight={'1em'} py={responsiveFontSize(22)}>
									<Heading as={'span'} flex={1} textAlign={'left'}>
										{isExpanded ? 'Read less' : 'Read more'}
									</Heading>
									{isExpanded &&
									<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect y="0.707107" width="13.1018" height="1.00783"
													transform="matrix(0.712558 0.701613 -0.712558 0.701613 2.44038 0.210992)" stroke="black"
													stroke-width="1.00783"/>
										<rect x="-0.718138" y="-6.00714e-08" width="13.1018" height="1.00783"
													transform="matrix(-0.712558 0.701613 -0.712558 -0.701613 10.7604 1.91792)" stroke="black"
													stroke-width="1.00783"/>
									</svg>}
									{!isExpanded &&
									<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect x="-0.5" y="0.5" width="13.1018" height="1.00783"
													transform="matrix(0.00773972 0.99997 -0.99997 -0.00773972 9.01948 2.01948)" stroke="black"
													stroke-width="1.00783"/>
										<rect x="-0.5078" y="-0.5078" width="13.1018" height="1.00783"
													transform="matrix(-0.99997 -0.00773981 -0.00773964 -0.99997 14.1055 8.10548)" stroke="black"
													stroke-width="1.00783"/>
									</svg>}
								</AccordionButton>
								<AccordionPanel fontSize={responsiveFontSize(22)} lineHeight={responsiveFontSize(32)}
																py={responsiveFontSize(60)} px={0}>
									<ModuleSerializer blocks={section._rawReadMore}/>
								</AccordionPanel>
							</React.Fragment>)}
						</AccordionItem>
					</Accordion>}
				</FadeIn>
			})}
		</SimpleGrid>
		<Box h={responsiveFontSize(400)}/>
	</React.Fragment>
}

export default GotLightPage
