import {graphql, useStaticQuery} from 'gatsby'

const useGotLight = (lang = 'en_GB') => {
	const {allSanityGotLight} = useStaticQuery(
		graphql`
      query {
        allSanityGotLight {
          nodes {
            logo {
              asset {
                url
              }
            }
            title
            _rawIntroContent(resolveReferences: {maxDepth: 10})
            video
            _rawMainContent(resolveReferences: {maxDepth: 10})
            _rawShowMore(resolveReferences: {maxDepth: 10})
            images {
              asset {
                url
              }
            }
						sections{
							title
							banner{
								asset{
									url
								}
							}
              _rawContent(resolveReferences: {maxDepth: 10})
              _rawReadMore(resolveReferences: {maxDepth: 10})
						}
          }
        }
      }
		`)
	// const homepage = allSanityHomepage.nodes.find(node => node.i18n_lang === lang)
	const document = allSanityGotLight.nodes[0]
	if (!document) {
		console.error('No document found!', lang)
	}
	return document
}

export default useGotLight
